@import '@assets/scss/v2/main';

.container {
  align-self: start;
  text-decoration: underline;
}

.button {
  background-color: transparent;
  text-decoration: underline;
  padding: 1rem;
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.backArrowText {
  vertical-align: text-top;
}
