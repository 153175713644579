@import '@assets/scss/v2/main';

.container {
  text-align: left;
}

.iconContainer {
  justify-content: flex-start;
}

.noLeftPadding {
  padding-left: 0;
}

.onlyIconContainer {
  border-radius: 50% !important;
  min-width: 4.8rem;
  min-height: 4.8rem;
  width: 4.8rem;
  height: 4.8rem;
}

.iconLeft {
  align-items: center;
  display: flex;
  min-height: 2.4rem;
  width: 2.4rem;
  margin-right: 0.8rem;
  &.onlyIcon {
    margin-right: 0;
  }
}

.iconRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-left: 0.5rem;
}

.iconRight svg {
  transition: 0.1s;
  & path {
    transition: 0.1s;
  }
}

.arrowDown {
  transform: rotate(90deg);
  margin-top: -0.3rem;
}
