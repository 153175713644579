@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.bigButton {
  @include textExtraLargeBold;

  border: none;
  user-select: none;
  cursor: pointer;
  width: 100%;
  background: $blue-dark;
  color: $white;
  border-radius: 4px;
  text-align: center;
  height: 6.4rem;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.fullWidth {
  width: 85vw;

  @include respond(m) {
    width: initial;
  }
}
