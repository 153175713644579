@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-default {
  text-align: left;

  @include respond(m) {
    text-align: center;
  }
}

.container-medium {
  text-align: left;

  @include respond(m) {
    text-align: center;
  }
}

.container-checkoutActionPoint {
  justify-content: flex-start;
  max-width: 31.5rem;
  margin: 1rem auto 0 auto;

  @include respond(m) {
    margin: 1rem auto;
  }
}

.container-checkoutHeader {
  justify-content: center;
  margin-top: 2rem;
}

.container-takeoverHeader {
  justify-content: center;
  margin-top: 2rem;
}

.container-confirmationHeader {
  justify-content: center;
  margin: 2rem 0 1.6rem;
}

.image {
  display: flex;
  margin: 0 auto;
  width: 4.8rem;
}

.image-checkoutHeader {
  width: fit-content;
}

.image-takeoverHeader {
  width: fit-content;
}

.image-confirmationHeader {
  width: fit-content;
}

.label-default {
  width: 100%;
  display: block;
  color: $black;
  justify-content: left;

  @include respond(m) {
    text-align: center;
  }

  @include headline;
}

.label-myBluemovement {
  width: 100%;
  display: block;
  color: $blue-bright !important;
  justify-content: left;

  @include respond(m) {
    text-align: center;
  }

  @include textExtraLargeBold;
}

.label-myBluemovementBlackLabel {
  width: 100%;
  display: block;
  color: $black !important;
  justify-content: left;

  @include respond(m) {
    text-align: center;
  }

  @include textExtraLargeBold;
}

.label-heroComponent {
  width: 100%;
  display: block;
  color: $black;
  justify-content: left;
  text-align: left;

  @include headline;
}

.label-medium {
  max-width: 73rem;
  display: block;
  width: 100%;
  color: $black;

  @include title;
}

.label-checkoutHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 1.4rem 0;

  @include headline;
  color: $white;
}

.label-takeoverHeader {
  width: 100%;
  display: block;
  color: $blue-bright !important;
  justify-content: left;

  @include textExtraLargeBold;
}

.label-confirmationHeader {
  width: 100%;
  display: block;
  color: $white !important;
  justify-content: left;

  @include textExtraLargeBold;
}

.label-checkoutActionPoint {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;

  @include textMediumBold;
  color: $white;
}

.text-default,
.text-myBluemovement {
  width: 100%;
  display: block;
  line-height: 150%;
  margin-top: 1.6rem;
  color: $black;

  @include respond(m) {
    text-align: center;
  }
}

.text-default,
.text-myBluemovementBlackLabel {
  width: 100%;
  display: block;
  line-height: 150%;
  margin-top: 1.6rem;
  color: $black;

  @include respond(m) {
    text-align: center;
  }
}

.text-medium {
  display: block;
  line-height: 150%;
  margin-top: 1.6rem;
  color: $black;
}

.text-checkoutHeader {
  @include font-sans;
  @include font-sans-medium;
  @include text-2xl;
  @include line-height-normal;

  text-align: center;
  color: $white;
}

.text-takeoverHeader {
  width: 100%;
  display: block;
  line-height: 150%;
  margin-top: 1.6rem;
  color: $black;
}

.text-confirmationHeader {
  width: 100%;
  display: block;
  line-height: 150%;
  margin-top: 1.6rem;
  color: $white;
}

.text-checkoutActionPoint {
  @include textSmall;

  text-align: center;
  color: $white;
}

.left {
  text-align: left;
  & > * {
    text-align: left;
  }
}

.contentContainerBackgroundBlueBright {
  background-color: $blue-bright;

  > * {
    color: $white;
  }
}

.contentContainerBackgroundBlueDark {
  background-color: $blue-dark;

  > * {
    color: $white;
  }
}

.contentContainerBackgroundBlueLight {
  background-color: $blue-light;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandYellow {
  background-color: $brand-yellow;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandPink {
  background-color: $brand-pink;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandGreen {
  background-color: $brand-green;

  > * {
    color: $black;
  }
}

.videoContainer {
  margin-top: 2.1rem;

  @include respond(m) {
    margin-top: 6.4rem;
  }

  > * {
    width: 100% !important;
    max-width: 100% !important;
  }
}
