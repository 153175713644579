@import '@assets/scss/v2/main';

.container {
  text-align: left;
}

.logo {
  display: flex;
  align-items: center;
  max-height: 4.8rem;
  
  & picture {
    padding-right: 1.6rem;
    height: 1.6rem;
  }
  & img {
    display: block;
    height: 100% !important;
    max-width: 9rem !important;
  }
}
