@import '@assets/scss/v2/main';

.container {
  display: flex;
}

.button {
  @include navigationFooter;
  order: 1;
  border: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  color: $white;
  background-color: $blue-bright;
  padding: 1.2rem 1.6rem;
  flex-direction: row;
  border-radius: 0 4px 4px 0;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
}

.buttonText {
  margin-left: 10px;
  font-weight: 700;
}
