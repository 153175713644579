@import '@assets/scss/v2/main';

.button {
  @include textBold;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background: $white;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
