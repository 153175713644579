.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.alignCenter {
  justify-content: center;
}

.alignLeft {
  justify-content: flex-start;
}

.alignRight {
  justify-content: flex-end;
}
