@import '@assets/scss/v2/main';

.container {
  display: flex;
}

.button {
  @include textBold;

  border: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  color: $white;
  height: 4.5rem;
  padding: 1.2rem 1.6rem;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;

  & .textField {
    color: $white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.blueBright {
  background-color: $blue-bright;
}

.blueDark {
  background-color: $blue-dark;
}

.blueLight {
  background-color: $blue-light;
}

.brandPink {
  background-color: $brand-pink;
}

.arrow {
  margin-left: 1.3rem;
}

.mobileFullWidth {
  width: 85vw;

  @include respond(m) {
    width: initial;
  }
}

.fullWidth {
  width: 100%;
}
