@import '@assets/scss/v2/main';

.item {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  @include respond(xs) {
    padding: 1.6rem 0;
  }

  @include respond(s) {
    padding: 1.6rem 0;
  }

  @include respond(m) {
    padding: 2.4rem 0;
  }
}

.item:only-child {
  padding: 0 !important;
}

.item:first-child {
  padding: 0 0 2.4rem 0;

  @include respond(xs) {
    padding: 0 0 1.6rem 0;
  }

  @include respond(s) {
    padding: 0 0 1.6rem 0;
  }

  @include respond(m) {
    padding: 0 0 2.4rem 0;
  }
}

.item:last-child {
  border-bottom: none;
  padding: 2.4rem 0 0 0;

  @include respond(xs) {
    padding: 1.6rem 0 0 0;
  }

  @include respond(s) {
    padding: 1.6rem 0 0 0;
  }

  @include respond(m) {
    padding: 2.4rem 0 0 0;
  }
}

.content {
  @include text;

  color: $black;
  margin-top: 2.4rem;
  line-height: 1.5rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {
  cursor: pointer;
  color: $black;
}

.plusSign {
  @include textExtraLarge;

  cursor: pointer;
  color: $black;
  user-select: none;
  line-height: 1.3rem;
}

.text {
  @include textMedium;

  @include respond(m) {
    @include textLargeMedium;
  }
}
