@import '@assets/scss/v2/main';

.container {
  border: 2px solid $black;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  width: 100%;
  padding: 1.6rem 1.2rem;
  color: $black;
}

.shadowGrounded {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.shadowElevated {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.info {
  border-color: $blue-bright;
}

.checkoutDelivery {
  border: none;
  border-radius: 0.4rem;
  background: rgba(245, 192, 67, 0.4);
}

.alice {
  background: $blue-alice;
  border-color: $blue-light;
}

.warning {
  border-color: $brand-yellow;
  background-color: $white;
}

.error {
  border-color: $contextual-error;
  background-color: $contextual-error-light;
}

.success {
  border-color: $brand-green;
}

.delivery {
  border: none;
  background-color: $white;
}

.filledBlue {
  background-color: $blue-bright;
  border-color: $blue-bright;

  p {
    @include textMedium;
  }
}

.filledYellow {
  background-color: $brand-yellow;
  border-color: $brand-yellow;
}

.clockYellow {
  background-color: rgba($brand-yellow, 0.4);
  border-color: rgba($brand-yellow, 0);
}

.gold {
  background-color: $transparent-gold;
  border-color: $transparent-gold;
}

.content {
  display: flex;
  width: 100%;
  color: $black;
}

.contentWithTitle {
  flex-direction: column;
}

.header {
  color: $black;
  fill: $grey-tabby;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: auto;
}

.headerWithTitle {
  .icon {
    opacity: 0.7;
  }
}

.contentText {
  margin-left: 0.2rem;
}

.contentTextWithTitle {
  margin-left: 3.3rem;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
}

.closeButton {
  width: 2.1rem;
  height: 2.1rem;
}

.additionalMarginLeft {
  margin-left: 3.4rem;
}

.additionalMarginBottom {
  margin-bottom: 0.6rem;
}

.text {
  @include text;

  @include respond(m) {
    @include text-base;
  }
}

.titleText {
  @include textBold;

  @include respond(m) {
    @include text-base;
  }
}

.titleSubheading {
  @include subheading;

  @include respond(m) {
    @include text-xl;
    margin-left: 0.1rem;
  }
}

.textWhite {
  color: $white !important;

  p {
    color: $white !important;
  }
}

.textMedium {
  @include textMedium;

  p {
    @include textMedium;
  }
}

.hidden {
  display: none;
}
