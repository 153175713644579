@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.darkBlueButton {
  @include textBold;

  border: none;
  user-select: none;
  cursor: pointer;
  height: 5.4rem;
  width: 100%;
  background: $blue-dark;
  color: $white;
  border-radius: 4px;
  padding: 1.5rem 1.2rem;
  text-align: center;

  &:hover {
    background: $blue-bright;
  }

  &:disabled {
    background-color: $grey;
    cursor: default;
  }
}

.fullWidth {
  width: 85vw;

  @include respond(m) {
    width: initial;
  }
}
