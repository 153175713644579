@import '@assets/scss/v2/main';

.container {
  @include text;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  color: inherit;

  word-break: break-word;
  
  > button:not(:last-child) {
    margin: -0.8rem 0;

    @include respond(m) {
      margin: -1.2rem 0;
    }
  }
}

.p {
  color: unset;
}

.ol {
  padding-left: 0.5em;

  .li {
    margin-left: 1em;

    > svg {
      display: none;
    }

    > ul li {
      display: flex;
      margin-left: -1.5em;

      > svg {
        display: block;
        flex-shrink: 0;
        min-width: 24px;
      }
    }
  }
}

.ul {
  list-style: none;

  .li {
    display: flex;
    margin-bottom: 1rem;

    > div {
      margin-left: 0.8rem;
      display: flex;
      flex-wrap: wrap;
    }

    > div > * {
      margin-left: 0.8rem;
      line-break: auto;
    }

    > p {
      margin-left: 0.25rem;
      color: unset;
    }

    > svg {
      display: block;
      flex-shrink: 0;
      min-width: 24px;
    }
  }
}

.hero {
  @include hero;

  color: unset;
}

.headline {
  @include headline;

  color: unset;
}

.title {
  @include title;
  color: unset;
}

.subtitle {
  @include subtitle;
  color: unset;
}

.lead {
  @include copyLead;
  color: unset;
}

.text {
  @include text;
  color: unset;
}

.link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.textBold {
  font-size: inherit;
  color: inherit;
}
