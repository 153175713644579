.container {
  display: flex;
  width: 100%;
}

.contentContainer {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  width: 100%;
}

.icon {
  margin-right: 0.5rem;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 100%;
  width: 100%;
}
