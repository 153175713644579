@import '@assets/scss/v2/main';

.container {
  display: flex;
  align-items: center;
  border: none;
  user-select: none;
  cursor: pointer;
  background-color: initial;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  min-height: 4.4rem;
  max-width: 100%;
  padding: 1.2rem 1.6rem;
  font-weight: bold;
  transition: background-color 0.1s, color 0.3s;
  border-radius: 0.3rem;
  justify-content: center;
  line-height: 100%;

  &.noLeftPadding {
    padding-left: 0;
  }

  &.elevated {
    box-shadow: 0px 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
  }

  &.tiny {
    min-height: 3.4rem;
  }

  &.fullWidth {
    width: 100%;
  }

  &:disabled,
  &.isLoading {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  & svg {
    transition: 0.1s;

    & path {
      transition: 0.1s;
    }
  }

  @include respond(m) {
    min-height: 4.8rem;
    &.tiny {
      min-height: 3.5rem;
    }
  }
}

.theme-primary {
  background-color: $blue-bright;
  color: $white;
  &:not(.coloredIcon) svg {
    fill: $white;
    transition: 0.1s;
    & path {
      fill: $white;
      transition: 0.1s;
    }
  }
  & [data-loader='loader'] [data-color] {
    border-color: $white $white $white transparent;
  }

  &:hover {
    background-color: $blue-light;
    color: $blue-dark;
    &:not(.coloredIcon) svg {
      fill: $blue-dark;
      & path {
        fill: $blue-dark;
      }
    }
    &::after {
      border-color: transparent $blue-light transparent transparent;
    }
  }
  &:active {
    background-color: $blue-pressed;
    color: $blue-dark;

    &::after {
      border-color: transparent $blue-pressed transparent transparent;
    }
  }
  &:focus-visible {
    outline: 2px solid $blue-dark;
  }
}

.theme-secondary {
  background-color: $blue-dark;
  color: $white;
  &:not(.coloredIcon) svg {
    fill: $white;

    & path {
      fill: $white;
    }
  }

  & [data-color] {
    border-color: $white $white $white transparent;
  }

  &:hover {
    background-color: $blue-bright;
  }
  &:active {
    background-color: $blue-pressed;
    color: $blue-dark;

    &:not(.coloredIcon) svg {
      fill: $blue-dark;

      & path {
        fill: $blue-dark;
      }
    }
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-tertiary {
  background-color: $blue-light;
  color: $blue-dark;

  & [data-color] {
    border-color: $blue-dark $blue-dark $blue-dark transparent;
  }

  &:hover {
    background-color: $blue-pressed;
  }
  &:active {
    background-color: $blue-dark;
    color: $white;
    &:not(.coloredIcon) svg {
      fill: $white;

      & path {
        fill: $white;
      }
    }
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-white {
  background-color: $white;
  color: $blue-dark;
  
  & svg {
    & path {
    }
  }

  & [data-color] {
    border-color: $blue-dark $blue-dark $blue-dark transparent;
  }

  &:hover {
    background-color: $blue-solitude;
  }
  &:active {
    background-color: $blue-pressed;
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-raw-on-light {
  background-color: transparent;
  color: $blue-dark;

  & [data-color] {
    border-color: $blue-dark $blue-dark $blue-dark transparent;
  }

  &:hover {
    background-color: $blue-solitude;
  }
  &:active {
    background-color: $blue-pressed;
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-raw-on-dark {
  background-color: transparent;
  color: $white;

  & [data-color] {
    border-color: $white $white $white transparent;
  }

  &:not(.coloredIcon) svg {
    fill: $white;

    & path {
      fill: $white;
    }
  }

  &:hover {
    background-color: $blue-solitude;
    color: $blue-dark;
    &:not(.coloredIcon) svg {
      fill: $blue-dark;

      & path {
        fill: $blue-dark;
      }
    }
  }

  &:active {
    background-color: $blue-pressed;
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-green {
  background-color: $success-200;
  color: $blue-dark;

  & [data-color] {
    border-color: $blue-dark $blue-dark $blue-dark transparent;
  }

  &:not(.coloredIcon) svg {
    fill: $blue-dark;

    & path {
      fill: $blue-dark;
    }
  }

  &:hover {
    background-color: $success-300;
  }

  &:active {
    background-color: $green;
    color: $white;
    &:not(.coloredIcon) svg {
      fill: $white;

      & path {
        fill: $white;
      }
    }
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}

.theme-soft-blue {
  background-color: $blue-solitude;
  color: $blue-dark;

  & [data-color] {
    border-color: $blue-dark $blue-dark $blue-dark transparent;
  }

  &:not(.coloredIcon) svg {
    fill: $blue-dark;

    & path {
      fill: $blue-dark;
    }
  }

  &:hover {
    background-color: $blue-light;
  }

  &:active {
    background-color: $blue-pressed;
  }
  &:focus-visible {
    outline: 2px solid $blue-bright;
  }
}
