@import '@assets/scss/v2/main';

.container {
  display: flex;
  border: none;
  user-select: none;
  cursor: pointer;
  background-color: initial;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  &:disabled,
  &.isLoading {
    cursor: default;
  }
}

.link {
  margin: 0 0.1rem;
}

.fullWidth {
  width: 100%;
}

.btn {
  position: relative;
  display: flex;
  border-radius: 0.3rem;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  transition: all 0.3s ease-out;
  min-height: 4.4rem;
  padding: 1rem 0.8rem;

  @include respond(m) {
    min-height: 5.6rem;
    padding: 1.6rem;
  }

  &:focus {
    border: 2px solid $blue-dark;
  }

  button:disabled &,
  .isLoading & {
    pointer-events: none;
  }

  button:disabled & {
    opacity: 50%;
  }

  &.fullWidth {
    width: 100%;
  }

  &.small {
    min-height: 4.4rem;
    padding: 1rem 0.8rem;
  }

  &.medium {
    min-height: 5.6rem;
    padding: 1.6rem;
  }

  &.center {
    justify-content: center;
  }

  & .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & .hideElement {
    opacity: 0;
  }

  & .iconLeft {
    display: block;
    max-height: 2.4rem;
    min-width: 2.4rem;
    max-width: 9rem;
  }

  & .text {
    @include textMediumBold;
    @include line-height-thight;
    text-align: left;
    padding: 0 0.8rem;
    color: inherit;
  }

  & .iconRight {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  & .rotate {
    transform: rotate(90deg);
  }

  &.primary {
    background-color: $blue-bright;
    color: $white;
    stroke: $white;
    fill: $white;

    &:hover {
      background-color: $blue-light;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }

    &:active {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }
  }

  &.secondary {
    background-color: $blue-dark;
    color: $white;
    stroke: $white;
    fill: $white;

    &:hover {
      background-color: $blue-bright;
      color: $white;
      stroke: $white;
      fill: $white;
    }

    &:active {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }
  }

  &.tertiary {
    background-color: $blue-light;
    color: $blue-dark;
    stroke: $blue-dark;
    fill: $blue-dark;

    &:hover {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }

    &:active {
      background-color: $blue-dark;
      color: $white;
      stroke: $white;
      fill: $white;
    }
  }

  &.elevated {
    background-color: $white;
    color: $blue-dark;
    stroke: $blue-dark;
    fill: $blue-dark;
    box-shadow: 0px 0.1rem 0.4rem rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: $blue-solitude;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }

    &:active {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }
  }

  &.raw {
    background-color: transparent;
    color: $blue-dark;
    stroke: $blue-dark;
    fill: $blue-dark;

    &:hover {
      background-color: $blue-solitude;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }

    &:active {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }
  }

  &.rawDark {
    background-color: transparent;
    color: $white;
    stroke: $white;
    fill: $white;

    &:hover {
      background-color: $blue-solitude;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }

    &:active {
      background-color: #5eb0fc;
      color: $blue-dark;
      stroke: $blue-dark;
      fill: $blue-dark;
    }
  }
}
