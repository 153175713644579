@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  user-select: none;
  cursor: pointer;
}

.backButton {
  @include textBold;

  user-select: none;
  cursor: pointer;
  display: flex;
  height: 4rem;
  border: none;
  background: $blue-bright;
  color: $white;
  border-radius: 0 4px 4px 0;
  padding: 1.2rem;
  text-align: center;
}
