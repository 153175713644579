@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.bigButton {
  @include textExtraLargeMediumBold;

  user-select: none;
  cursor: pointer;
  width: 100%;
  background: $brand-pink;
  color: $white;
  border: none;
  border-radius: 4px;
  padding: 2rem 1.6rem;
  text-align: center;
}

.fullWidth {
  width: 85vw;

  @include respond(m) {
    width: initial;
  }
}
