@import '@assets/scss/v2/main';

.button {
  @include textBold;

  background-color: transparent;
  text-decoration: underline;
  padding: 1rem;
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
