@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.blueButton {
  @include textBold;

  border: none;
  user-select: none;
  cursor: pointer;
  width: 100%;
  background: $blue-bright;
  color: $white;
  border-radius: 4px;
  padding: 1.25rem 1.2rem;
  text-align: center;

  &:hover {
    background: $blue-dark;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      background: $blue-bright;
    }
  }
}

.blueLight {
  background-color: $blue-light;
  &:disabled {
    cursor: not-allowed;
    color: $black;
  }
  &:hover {
    background: $blue-light;
  }
}

.fullWidth {
  width: 85vw;

  @include respond(m) {
    width: initial;
  }
}
