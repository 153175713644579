@import '@assets/scss/v2/main';

.title {
  @include headingSubtitleBold;

  color: unset;
  margin-bottom: 1.2rem;
}

.additionalPadding {
  padding-bottom: 2.4rem;
}

.text {
  @include text;

  color: unset;
}
