@import '@assets/scss/shared/variables';

.container {
  display: inline-block;
  position: relative;
  height: 50%;
  max-height: 80px;
  aspect-ratio: 1;
  min-width: 3%;
}

.loader {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-dark {
  border: 4px solid $blue-dark;
  border-color: $blue-dark $blue-dark $blue-dark transparent;
}

.loader-light {
  border: 4px solid $white;
  border-color: $white $white $white transparent;
}

.firstDelay {
  animation-delay: -0.45s;
}

.secondDelay {
  animation-delay: -0.3s;
}

.thirdDelay {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
