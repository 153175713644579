@import '@assets/scss/v2/main';

.playerWrapper {
  width: 100%;
  max-width: 64rem;
  height: 100%;
}

.fullWidth {
  max-width: 100% !important;
}

.player {
  width: 100% !important;
  max-width: 64rem;
  background-color: $black;
}
