@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
}

.notElevated {
  background: transparent;
  margin-bottom: 10rem;
  padding: 2.4rem 1.6rem;
}
.elevated {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 2.4rem 1.6rem;

  @include respond(m) {
    padding: 3.2rem 4rem;
  }
}
